import { combineReducers } from "redux";
import authReducer from "./authReducer";
import vehicleReducer from "./vehicleReducer";
import filterReducer from "./filterCollapseReducer";
import sideMenuReducer from "./sideMenuReducer";

const rootReducer = combineReducers({
  authReducer,
  vehicleReducer,
  filterReducer,
  sideMenuReducer
});

export default rootReducer;
